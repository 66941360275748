<template>
  <!-- <router-view class="container" /> -->
  <router-view v-slot="{ Component }" class="container">
    <keep-alive>
      <component v-if="$route.meta.keepAlive" :is="Component" :key="$route.path" />
    </keep-alive>
  </router-view>
  <router-view v-if="!$route.meta.keepAlive" class="container"></router-view>
</template>
<script>
  import {getSignature } from '@/service/course'
  import wx from "weixin-js-sdk"
  export default{
    data(){
      return{
        wechat:{},
      }
    },
    mounted:function(){
      const url= location.href.split('#')[0];
      getSignature({url:url}).then(res=>{
        this.wechat=res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: this.wechat.appId, // 必填，公众号的唯一标识
          timestamp: this.wechat.timestamp, // 必填，生成签名的时间戳
          nonceStr: this.wechat.noncestr, // 必填，生成签名的随机串
          signature: this.wechat.signature, // 必填，签名，见附录1
          jsApiList: ['checkJsApi', 'chooseWXPay',"openAddress"]
          // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
      })
    }
  }



</script>

<style lang="scss">
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
body {
  background-color: #f5f5f5;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  font-size: 14px;
}
.container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
</style>
