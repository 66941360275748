import axios from "axios";
console.log("打印开发环境")
console.log(process.env)
let baseURL=''
// if (process.env.NODE_ENV=='development') {
//   baseURL = `/api`
// }
const api = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  responseType: "json",
});
export default api;

