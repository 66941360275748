// function loggedIn() {
//   return !!localStorage.getItem("token-hyxt");
// }
// export namespace auth {
//   function loggedIn() {
//     return !!localStorage.getItem("token-hyxt");
//   }
// }
// const auth = {
//   loggedIn: () => {
//     return !!localStorage.token
//   },
// }
const loggedIn = () => {
  return !!localStorage.getItem("token-hyxt");
}

export default {
  isLoggedIn: loggedIn
};
