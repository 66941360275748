// import Vue from "vue";
import api from "./api/index";
import { FloatingBubble } from 'vant';
// Vue.prototype.$axios = axios;
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'vant/lib/index.css'; // 全局引入样式
import "./assets/font_3174441_mrffwo7zq6/iconfont.css" //引入自定义图标
import { createApp } from "vue";
import { Form, AddressEdit, AddressList, Field, Cell, SwipeCell, CellGroup, Icon, Button, Swipe, SwipeItem, PullRefresh, List, Tab,Toast, Tabs, NoticeBar, TreeSelect, Search, Image as VanImage, Grid, GridItem, NavBar, CouponCell, CouponList, Card, Tag, Popup, ActionBar, ActionBarIcon, ActionBarButton, Rate, ShareSheet, SubmitBar, Stepper, Dialog, Checkbox, CheckboxGroup,RadioGroup,Radio } from 'vant'
import "./mock";
const app = createApp(App) // 创建实例
app.use(Form)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(AddressEdit)
  .use(AddressList)
  .use(Field)
  .use(Cell)
  .use(SwipeCell)
  .use(CellGroup)
  .use(Icon)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(PullRefresh)
  .use(List)
  .use(Tab)
  .use(Tabs)
  .use(Toast)
  .use(NoticeBar)
  .use(TreeSelect)
  .use(Search)
  .use(VanImage)
  .use(Grid)
  .use(GridItem)
  .use(NavBar)
  .use(CouponCell)
  .use(CouponList)
  .use(Card)
  .use(Tag)
  .use(Popup)
  .use(ActionBar)
  .use(ActionBarIcon)
  .use(ActionBarButton)
  .use(Rate)
  .use(ShareSheet)
  .use(SubmitBar)
  .use(Stepper)
  .use(Dialog)
  .use(RadioGroup)
  .use(Radio)
  .use(FloatingBubble)


  // .use(Sticky)

app.use(store)
app.use(router)



app.mount("#app")
app.config.globalProperties.api=api;





