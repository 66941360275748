module.exports = [
  {
    url: 'common/carousel',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        carousels: [
          {
            carouselUrl: "https://newbee-mall.oss-cn-beijing.aliyuncs.com/images/banner-p50-pocket.png",
            redirectUrl: "https://juejin.im/book/6844733826191589390"
          },{
            carouselUrl: "https://newbee-mall.oss-cn-beijing.aliyuncs.com/images/banner-iphone13.png",
            redirectUrl: "https://juejin.im/book/6844733826191589390"
          },{
            carouselUrl: "https://newbee-mall.oss-cn-beijing.aliyuncs.com/images/banner-mate40.png",
            redirectUrl: "https://juejin.im/book/6844733826191589390"
          }
        ],
      }
    }
  },
  {
    url: 'common/courses',
    type: 'get',
    result: {
      status: 0,
      error: '',
      data: [
        {
          courseName: "汉源背词典1级（第1-348页）",
          isValid: 1
        },
        {
          courseName: "汉源背词典2级（第349-697页）",
          isValid: 0
        }
      ]
    }
  }
]
