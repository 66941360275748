module.exports = [
  {
    url: 'goods/getListByOrdering',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        list: [
          {
            id: '@id',
            title: '@ctitle(4, 50)',
            thumb: '@image("800x500", "#894FC4", "#FFF", "png", "goods cover")',
            price: '@float(10, 100, 0, 2)', // 选项里最低价
            salesVolume: '@integer(0,200)',
          }
        ]
      },
    }
  },
  {
    url: 'goods/detail',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        id: '@id',
        title: '@ctitle(2, 20)',
        subtitle: '@ctitle(0, 20)',
        type: '@integer(0,200)',  // 可按类型适配优惠券，参考“行政区划代码”设定
        thumbs: [
          '@image("414x400", "#66C6F2", "#FFF", "png", "goods thumbnail 1")',
          '@image("414x400", "#66C6F2", "#FFF", "png", "goods thumbnail 2")',
          '@image("414x400", "#66C6F2", "#FFF", "png", "goods thumbnail 3")',
        ],
        options: [
          {
            title: '@ctitle(2, 10)',
            price: '@float(10, 100, 0, 2)',
            thumb: '@image("400x400", "#66C6F2", "#FFF", "png", "goods thumbnail")',
          },
          {
            title: '@ctitle(2, 20)',
            price: '@float(10, 100, 0, 2)',
            thumb: '@image("400x400", "#66C6F2", "#FFF", "png", "goods thumbnail")',
          },
          {
            title: '@ctitle(2, 20)',
            price: '@float(10, 100, 0, 2)',
            thumb: '@image("400x400", "#66C6F2", "#FFF", "png", "goods thumbnail")',
          }
        ],
        price: '@float(10, 100, 1, 2)', // 选项最低价
        maxPrice: '@float(20, 100, 1, 2)',  // 选项最高价
        salesVolume: '@integer(0,200)',
        intro: [
          '@image("800x500", "#894FC4", "#FFF", "png", "intro1")',
          '@image("800x300", "#894FC4", "#FFF", "png", "intro2")',
          '@image("800x108", "#894FC4", "#FFF", "png", "intro3")',
          '@image("800x60", "#894FC4", "#FFF", "png", "intro4")',
          '@image("800x128", "#894FC4", "#FFF", "png", "intro5")',
          '@image("800x260", "#894FC4", "#FFF", "png", "intro6")',
          '@image("800x360", "#894FC4", "#FFF", "png", "intro7")',
          '@image("800x460", "#894FC4", "#FFF", "png", "intro8")',
        ]
      },
    }
  },
  {
    url: 'goods/getCouponList',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        list: [
          {
            id: '@id',
            title: '@ctitle(2, 20)', // 优惠券标题
            value: '@integer(5,49)',  // 面值
            endDate: '@date("yyyy-MM-dd")', // 截止日期
          }
        ]
      },
    }
  },
  {
    url: 'goods/getListByCart',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        list: [
          {
            id: '@id',
            title: '@ctitle(2, 20)', // 书名
            subtitle: '@ctitle(0, 20)',  // 副标题
            type: '@integer(0,200)', // 商品类型
            price: '@float(10, 100, 0, 2)', // 售价
            originPrice: '@float(10, 100, 0, 2)', // 原价
            quantity: '@integer(1, 2)',  // 订购数量
            desc: '@cword(2, 12)', // 选项描述
            thumb: '@image("360x360", "#894FC4", "#FFF", "png", "bookthumb1")',  // 缩略图
          },
          {
            id: '@id',
            title: '@ctitle(2, 20)', // 书名
            subtitle: '@ctitle(0, 20)',  // 副标题
            type: '@integer(0,200)', // 商品类型
            price: '@float(10, 100, 0, 2)', // 售价
            originPrice: '@float(10, 100, 0, 2)', // 原价
            quantity: '@integer(1, 2)',  // 订购数量
            desc: '@cword(2, 12)', // 选项描述
            thumb: '@image("360x360", "#894FC4", "#FFF", "png", "bookthumb2")',  // 缩略图
          },
          {
            id: '@id',
            title: '@ctitle(2, 20)', // 书名
            subtitle: '@ctitle(0, 20)',  // 副标题
            type: '@integer(0,200)', // 商品类型
            price: '@float(10, 100, 0, 2)', // 售价
            originPrice: '@float(10, 100, 0, 2)', // 原价
            quantity: '@integer(1, 2)',  // 订购数量
            desc: '@cword(2, 12)', // 选项描述
            thumb: '@image("360x360", "#894FC4", "#FFF", "png", "bookthumb3")',  // 缩略图
          }
        ],
      }
    }
  }
]
