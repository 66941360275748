const Mock = require("mockjs");
module.exports = [
  {
    url: 'user/sendVerifyCode/',
    type: 'get',
    result: option => {
      return {
        resultCode: 200,
        message: 'SUCCESS',
        data: {
          phone: option.query.phone,
          vcode: '@string("number", 4)'
        }
      }
    }
  },
  {
    url: 'user/login',
    type: 'post',
    result: option => {
      return {
        resultCode: 200,
        message: 'SUCCESS',
        data: {
          uid: '@id',
          username: option.body.username,
          token: '@guid()',
          avatar: '@image("108x108", "#894FC4", "#FFF", "png", "avatar")',
          phone: '@string("number", 11)'
        }
      }
    }
  },
  {
    url: 'user/getAddressList',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        addressList: [
          {
            id: '1',
            name: '@cname',
            phone: '@phone',
            address: '@county(true) @csentence(8, 12)',
            isDefault: true,
            disabled: false
          },
          {
            id: '2',
            name: '@cname',
            phone: '@phone',
            address: '@county(true) @csentence(8, 12)',
            isDefault: false,
            disabled: false
          },
          {
            id: '3',
            name: '@cname',
            phone: '@phone',
            address: '@county(true) @csentence(8, 12)',
            isDefault: false,
            disabled: true
          }
        ]
      }
    }
  },
  {
    url: 'user/getAddress',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        id: '@id',
        name: '@cname',
        tel: '@phone',
        province: '@province',
        city: '@city',
        county: '@county',
        addressDetail: '@csentence(8, 12)',
        areaCode: '330702',
        postalCode: '@zip',
        isDefault: '@boolean'
      }
    }
  },
  {
    url: 'user/settingPassword',
    type: 'put',
    result: {
      resultCode: 200,
      message: 'SUCCESS'
    }
  },
  {
    url: 'user/getOrderList',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        list: [
          {
            orderNo: '@id',
            'status|1': ['待支付', '已完成', '已失效', '已退款'],  // 待支付、已完成、已失效、已退款
            totalPrice: '@float(20, 100,1,2)', // 商品总价
            discount: '@float(0, 1,1,2)', // 折扣
            paymentAmount: '@float(20, 100,1,2)', // 支付金额
            mode: '@integer(1, 4)', // 付款方式 1-微信；2-支付宝；3-网银；4-线下；
            goods: [
              {
                name: '学汉字长知识 一年级上',
                type: 'media',
                quantity: '@integer(1, 8)',
                price: '@float(20, 100,1,2)',
                originPrice: '@float(20, 100,1,2)',
                desc: '音频课程+讲义 u哦IQ瓦尔基里看见撒旦法拉客司机的法律武器可劲儿两岸三地法律是快递费',
                cover: '@image("102x127", "#894FC4", "#FFF", "png", "cover1")'
              },
              {
                name: '学汉字长知识 一年级下',
                type: 'media',
                quantity: '@integer(1, 8)',
                price: '@float(20, 100, 1, 2)',
                originPrice: null,
                desc: '音频课程 + 讲义',
                cover: '@image("102x127", "#00BFFF", "#fff", "png", "cover2")'
              }
            ],
            coupons: [
              {
                name: '通用优惠券',
                amount: '@integer(10, 30)',
                scope: 'all' ,// 适用范围 all-所有；demand-音视频点播；live-直播；book-书
                startDate: '@date("yyyy-MM-dd")', // 开始日期
                deadline: '@date("yyyy-MM-dd")', // 截止日期
                desc: '@csentence(8, 12)',  // 适用描述
              }
            ],
            delivery: {
              consignee: '@cname',
              tel: '@phone',
              prvince: '@province',
              city: '@city',
              county: '@county',
              addressDetail: '@csentence(8, 12)',
              provider: '中通快递',
              courierNumber: '@id',
              remark: '@csentence(0, 25)',
              fee: '@interger(8,20)', // 快递费
            },

          }
        ]
      }
    }
  },
  {
    url: 'user/getDefaultAddress',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        id: '@id',
        name: '@cname',
        tel: '@phone',
        province: '@province',
        city: '@city',
        county: '@county',
        addressDetail: '@csentence(8, 12)',
        areaCode: '330702',
        postalCode: '@zip',
        isDefault: '@boolean'
      },
    }
  },
  {
    url: 'user/getCouponList',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        list: [
          {
            id: '@id',
            name: '通用优惠券',
            value: '150',
            valueDesc: '1.5',
            unitDesc: '元',
            condition: '无门槛\n最多优惠12元',
            reason: '',
            startAt: + Mock.Random.date('T')/1000,
            endAt: + Mock.Random.date('T')/1000,
            'available|1': [1, 2],
          },
          {
            id: '@id',
            name: '通用优惠券',
            value: '2500',
            valueDesc: '25',
            unitDesc: '元',
            condition: '无门槛\n最多优惠12元',
            reason: '',
            description: '描述信息',
            startAt: + Mock.Random.date('T')/1000,
            endAt: + Mock.Random.date('T')/1000,
            'available|1': [1, 2],
          }
        ]
      }
    }
  }
]
