module.exports = [
  {
    url: 'course/byCategory',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        list: [
          {
            id: '@id',
            name: '学汉字 · 一年级(上)', // 课程名
            cover: '@/assets/images/xuehanziyishang.png', // 课程封面图
            price: 45,  // 单价
            originPrice: 50,  // 原价
            stdnum: 23, // 加入学习人数
          },
          // {
          //   name: '学汉字 · 一年级(下)',
          //   cover: '@/assets/images/xuehanziyixia.png',
          //   price: 45,
          //   originPrice: 50,
          //   stdnum: 15
          // },
          // {
          //   name: '学汉字 · 二年级(上)',
          //   cover: '@/assets/images/xuehanziershang.png',
          //   price: 45,
          //   originPrice: 50,
          //   stdnum: 15
          // },
          // {
          //   name: '学汉字 · 二年级(下)',
          //   cover: '@/assets/images/xuehanzierxia.png',
          //   price: 45,
          //   originPrice: 50,
          //   stdnum: 15
          // },
          // {
          //   name: '学汉字 · 三年级(上)',
          //   cover: '@/assets/images/xuehanzisanshang.png',
          //   price: 45,
          //   originPrice: 50,
          //   stdnum: 12
          // },
          // {
          //   name: '学汉字 · 三年级(下)',
          //   cover: '@/assets/images/xuehanzisanxia.png',
          //   price: 45,
          //   originPrice: 50,
          //   stdnum: 11
          // }
        ]
      }
    }
  },
  {
    url: 'course/detail',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        id: '@id',
        name: '学汉字 · 一年级(上)', // 课程名
        desc: '@csentence(8, 12)',  // 课程描述
        cover: '@image("686x385", "#894FC4", "#FFF", "png", "cover")' ,// 封面介绍图
        coverThumb: '@image("64x64", "#894FC4", "#FFF", "png", "cover thumb")', // 封面缩略图
        total: 90,  // 总量
        issued: 21, // 已更新数量
        price: 45,  // 选项最低价
        maxPrice: 50,  // 选项最高价
        subscribed: 23, // 加入学习人数
        lecturer: '孙万里',
        options: [
          {
            title: '2本教材（含配套音频讲解）',
            price: '@float(10, 100, 0, 2)',
            thumb: '@image("400x400", "#66C6F2", "#FFF", "png", "goods thumbnail")',
          },
        ],
        intro: [
          '@image("800x500", "#894FC4", "#FFF", "png", "intro1")',
          '@image("800x300", "#894FC4", "#FFF", "png", "intro2")',
          '@image("800x108", "#894FC4", "#FFF", "png", "intro3")',
          '@image("800x60", "#894FC4", "#FFF", "png", "intro4")',
          '@image("800x128", "#894FC4", "#FFF", "png", "intro5")',
          '@image("800x260", "#894FC4", "#FFF", "png", "intro6")',
          '@image("800x360", "#894FC4", "#FFF", "png", "intro7")',
          '@image("800x460", "#894FC4", "#FFF", "png", "intro8")',
        ]
      },
    }
  },
  {
    url: 'course/getLessons',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        lessons: [
          {
            id: '@id',
            title: '@increment(1) | @ctitle(8, 12)',
            subtitle: '@ctitle(2, 15)',
            playDuration: '@time("m:s")',
            playCount: '@integer(1, 1000)',
            isTrial: '@boolean',  // 不可试听lesson的播放地址为null或''
            mediaUrl: 'http://vod.hanyuanyuwen.com/f2607173be2c4655ac8adb838740e611/6bf9552fe6564aebaea714d83091da47-0a884b877c66f803095cae168182003b-ld.mp4',
          }
        ]
      },
    }
  },
  {
    url: 'course/getEvalList',
    type: 'get',
    result: {
      resultCode: 200,
      message: 'SUCCESS',
      data: {
        list: [
          {
            uid: '@id',
            nickname: '@cname',
            avatar: '@image("64x64", "#894FC4", "#FFF", "png", "avatar")',
            evalVal: '@integer(1,25)',
            likeNum: '@integer(0,100)',
            evalText: '@csentence(6, 140)',
            createDate: '@date("yyyy-MM-dd")',
          }
        ]
      }
    }
  }
]
