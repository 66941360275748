import axios from "@/utils/axios"
import api from "@/api"

// 获取课程分类
 export function getCourseCategory(params){
  return api.get("/index/categoryList",params)
 }

// 课程类别标签
export function getLabels(){
  return api.get("/index/labels")
}

// 获取签名
export function getSignature(param){
  return api.post("/order/getSignature",param)
}

// 按分类获取课程
export function getCourseList( id,search ) {
  return api.get("/category/info",{
    params:{
      id:id,
      search:search
    }
  });
}

// 推荐课程列表
export function recomendList() {
  return api.get("/category/recomendList");
}


// 订单详情
export function orderInfo( id,userId ) {
  return api.get("/order/orderInfo",{
    params:{
      id:id,
      userId:userId
    }
  });
}
// 获取课程详情
export function getCourseDetail( id ) {
  return api.get("/category/courseInfo", {
    params:{
      id:id
    }
  });
}
// 获取课程内容
export function getLessonList( id ) {
  return api.get("/category/sectionList", {
    params:{
      id:id
    }
  });
}

// 是否能购买
export function isDo( courseShopId,packId,userId ) {
  return api.get("/category/isDo", {
    params:{
      courseShopId:courseShopId,
      packId:packId,
      userId:userId
    }
  });
}

//微信支付
export function wxPay( params ) {
  return api.post("/order/pay",params);
}
// 获取课程评价
export function getEvalList( params ) {
  console.log("get course evaluation:", params);
  return axios.get("/mock/course/getEvalList", params);
}
