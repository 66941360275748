import axios from "axios";
import { Toast } from "vant";
// import router from "../router";

axios.defaults.baseURL = process.env.VUE_APP_API_ROOT;
axios.defaults.withCredentials = true;
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers["token"] = localStorage.getItem("token") || "";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.timeout = 10000;
axios.defaults.responseType = "json";

axios.interceptors.response.use((res) => {
  if (typeof res.data !== "object") {
    Toast.fail("服务端异常！");
    return Promise.reject(res);
  }
  // console.log(">>> result code:", res.data);
  if (res.data.resultCode != 200) {
    if (res.data.message) Toast.fail(res.data.message);
    // if (res.data.resultCode == 416) {
    //   router.push({ path: '/login' })
    // }
    return Promise.reject(res.data);
  }

  return res.data;
});

export default axios;
