import { createRouter, createWebHistory } from "vue-router";
import auth from "../utils/Auth";

const routes = [
  {
    path: "/",
    redirect: "/store"
  },
  {
    path: "/store",
    name: "store",
    component: () => import("@/views/Store.vue"),
    meta: {
      title: '汉源国学',
      index: 1,
    }
  },
  {
    path: "/category",
    name: "category",
    component: () => import("@/views/CourseCategory.vue"),
    meta: {
      title: '课程分类',
      index: 1,
    }
  },
  {
    path: "/courseDetail",
    name: "courseDetail",
    component: () => import("@/views/CourseDetail.vue"),
    meta: {
      title: '课程详情',
      index: 3,
    }
  },
  {
    path: "/store",
    name: "store",
    component: () => import("@/views/Store.vue"),
    meta: {
      title: '汉源商城',
      index: 1,
      keepAlive : true //页面被缓存
    }
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/User.vue"),
    meta: {
      title: '汉源国学',
      index: 1,
      requiresAuth: true,
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      keepAlive:true,
      title: '汉源国学',
      index: 1,
    }
  },
  {
    path: "/address",
    name: "address",
    component: () => import("@/views/Address.vue"),
    meta: {
      title: '地址列表',
      index: 3,
      requiresAuth: true,
    }
  },
  {
    path: "/addressEdit",
    name: "addressEdit",
    component: () => import("@/views/AddressEdit.vue"),
    meta: {
      title: '地址修改',
      index: 3,
      requiresAuth: true
    }
  },
  {
    path: "/coupon",
    name: "coupon",
    component: () => import("@/views/Coupon.vue"),
    meta: {
      title: '卡券管理',
      index: 2,
      requiresAuth: true
    }
  },
  {
    path: "/getCoupons",
    name: "getCoupons",
    component: () => import("@/views/GetCoupons.vue"),
    meta: {
      title: '领取优惠券',
      index: 2,
      requiresAuth: true
    }
  },
  {
    path: "/orderList",
    name: "orderList",
    component: () => import("@/views/OrderList.vue"),
    meta: {
      title: "订单列表",
      index: 2,
      requiresAuth: true
    }
  },
  {
    path: "/password",
    name: "password",
    component: () => import("@/views/Password.vue"),
    meta: {
      requiresAuth: true,
      title: "密码设置",
      index: 2,
    }
  },
  {
    path: "/goodsDetail",
    name: "goodsDetail",
    component: () => import("@/views/GoodsDetail.vue"),
    meta: {
      title: "商品详情",
      index: 3,
    }
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      keepAlive:true,
      title: "首页",
      index: 4,
    }
  },
  {
    path: "/pay/shop",
    name: "pay/shop",
    component: () => import("@/views/OrderForm.vue"),
    meta: {
      keepAlive:true,
      title: "提交订单",
      index: 4,
    }
  },
  {
    path: "/pay/book",
    name: "pay/book",
    component: () => import("@/views/ExOrderForm"),
    meta: {
      keepAlive:true,
      title: "订单详情",
      index: 5,
    }
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("@/views/Cart.vue"),
    meta: {
      title: "购物车",
      index: 4,
      requiresAuth: true,
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode:'history',
  routes,
});

// 清空路由历史记录
export function resetRouter () {
  const newRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode:'history',
    routes,
  })
  router.matcher = newRouter.matcher // the relevant part
}

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  console.log("to path:", to.name);
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.requiresAuth && !auth.isLoggedIn()) {
    // 此路由需要授权，请检查是否已登录
    // 如果没有，则重定向到登录页面
    // return {
    //   path: '/login',
    //   // 保存我们所在的位置，以便以后再来
    //   query: { redirect: to.fullPath },
    // }
    next({name: 'login', query: { redirect: to.fullPath, back: from.fullPath }})
  } else next()

})

export default router;
